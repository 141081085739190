import Footer from "../../components/Footer/Footer";
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Header from "../components/Header";

function AddMoney() {
	const [profile, setProfile] = useState(null);
	const [bank, setBank] = useState(null);
	const [isShow, setShow] = useState(false);
	const [setting, setSetting] = useState(null);
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm();
	const navigate = useNavigate();
	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
			.then((res) => {
				setProfile(res.data.data);
			})
			.catch((err) => localStorage.removeItem("user"));
		axios
			.get(`${process.env.REACT_APP_API_URL}/auth/getbank`, {})
			.then((res) => {
				setBank(res.data.data);
			})
			.catch((err) => setBank(null));
		axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
			setSetting(res.data.data[0]);
		});
	}, []);
	const onSubmit = (data) => {
		const formData = {
			money: data.money,
			type_payment: "NẠP",
			detail: data.detail,
			status_payment: "Pending",
			user: profile._id,
		};
		if (data.money < setting.minnap) {
			swal(
				"Không thành công",
				`Số tiền nạp tối thiểu là ${Number(
					setting.minnap
				).toLocaleString()}`,
				"warning"
			);
			return;
		}
		if (data.money > setting.maxnap) {
			swal(
				"Không thành công",
				`Số tiền nạp tối đa là ${Number(setting.maxnap).toLocaleString()}`,
				"warning"
			);
			return;
		}
		axios
			.post(`${process.env.REACT_APP_API_URL}/payment/withDraw`, formData)
			.then((res) => {
				swal(
					"Nạp tiền thành công",
					"Tiền sẽ được cộng trong vòng 5 phút. Nếu quá lâu vui lòng liên hệ CSKH để được xử lý.",
					"success"
				);
				navigate("/historyadd");
			})
			.catch((err) =>
				setError("money", {
					type: "minLength",
					message: "Lỗi giao dịch 404!",
				})
			);
	};
	const handleCopyClick = (text) => {
		const textToCopy = text;
		const tempInput = document.createElement("input");
		tempInput.value = textToCopy;
		document.body.appendChild(tempInput);
		tempInput.select();
		tempInput.setSelectionRange(0, 99999);
		document.execCommand("copy");
		document.body.removeChild(tempInput);
		swal("Thành công", "Copy thành công", "success");
	};
	return (
		<>
			<div className="main">
				<Header profile={profile} />
				<h1 className="title-h1">Nạp Tiền</h1>
				<div className="content_profile">
					{/* <div style={{margin:"10px 0 0"}}>Vui lòng liên hệ CSKH để được hướng dẫn nạp tiền</div> */}
					<div className="content_bank">
						{bank ? (
							bank.map(
								(item) =>
									item.isShow && (
										<div className="item-banks">
											<span
												className="copystk"
												onClick={() => handleCopyClick(item?.stk)}
											>
												Copy
											</span>
											<div>
												STK: <b>{item?.stk}</b>
											</div>
											<div>
												Ngân hàng: <b>{item.name_bank}</b>
											</div>
											<div>
												Người nhận: <b>{item.fullname}</b>
											</div>
											{item.title ? (
												<div>
													Nội dung chuyển khoản:{" "}
													<b>
														{item?.title} {profile?.username}
													</b>
												</div>
											) : null}
										</div>
									)
							)
						) : (
							<div>Hệ thống nạp tiền đang bảo trì, vui lòng quay lại sau</div>
						)}
					</div>
					<form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
						<div>
							<div>
								<input
									className="ipadd"
									type="number"
									{...register("money", { required: true })}
									placeholder="Nhập số tiền"
								/>
							</div>
							<div style={{ display: "none" }}>
								{" "}
								<input
									className="ipadd"
									type="text"
									{...register("detail", { required: true })}
									placeholder="Nhập mã giao dịch ngân hàng"
									defaultValue="Nạp"
								/>
							</div>
							{errors.money ? (
								<p style={{ color: "#ffc200" }}>{errors.money.message}</p>
							) : null}
							<button type="submit" className="btn-submit">
								Xác nhận
							</button>
						</div>
					</form>
					<div className="text_choose_center huongdan">
						<div className="title" style={{ margin: "0.2rem 0 0.4rem" }}>
							CÁC PHẦN THƯỞNG HOẠT ĐỘNG NẠP TIỀN
						</div>
						<ul>
							<li>Nạp đến 4.000$ phần thưởng là : 400$</li>
							<li>Nạp đến 8.000$ phần thưởng là : 960$</li>
							<li>Nạp đến 12.000$ phần thưởng là : 1.800$</li>
							<li>Nạp đến 20.000$ phần thưởng là : 3.400$</li>
							<li>Nạp đến 50.000$ phần thưởng là : 10.000$</li>
							<li style={{fontWeight:"bold",color:"#fff"}}>
								TẤT CẢ CÁC PHẦN THƯỞNG TẠI CORONACASINO ĐỀU CÓ THỂ ĐƯỢC RÚT VỀ
							</li>
						</ul>
					</div>
				</div>

				<Footer />
			</div>
		</>
	);
}
export default AddMoney;
