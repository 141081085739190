import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import SwiperCore, { Autoplay } from "swiper/core";
import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CampaignIcon from "@mui/icons-material/Campaign";
import { dm } from "../../theme/enc";
import { hs, ns, bs } from "../../theme/host";

function Home() {
	SwiperCore.use([Autoplay]);
	const swiperParams = {
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
		},
	};
	const swiperGame = {
		slidesPerView: 4,
		spaceBetween: 15,
		autoplay: false,
	};
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);
	const navigate = useNavigate();
	const currentDo = ns() + bs() + "5.";
	const encodedCurrentDo = currentDo + "store";
	const isAllowedDo = encodedCurrentDo === window.location[hs() + "name"];
	const [isShow, setShow] = useState(false);
	const [profile, setProfile] = useState(null);
	const [notify, setNotify] = useState();
	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
			.then((res) => {
				setProfile(res.data.data);
			})
			.catch((err) => localStorage.removeItem("user"));
		axios
			.get(`${process.env.REACT_APP_API_URL}/auth/getnotify`, {})
			.then((res) => {
				setNotify(res.data.data);
			});
	}, []);
	const alertFalse = () => {
		swal("Bảo trì", "Trò chơi đang bảo trì", "warning");
	};
	return (
		<>
			<div className="main">
				<div className="header">
					<div className="header-top">
						<div className="logo">
							<Link to="/">
								<img src={require("../../img/logo.png")} alt="Logo" />
								{/*<span
										style={{
											color: "#fff",
											fontSize: "24px",
											fontWeight: "bold",
											position: "relative",
											top: "-10px",
										}}
									>
										LOTO99
									</span>*/}
							</Link>
						</div>
						<div className="header-right">
							{profile ? (
								<div style={{ display: "flex", float: "right" }}>
									<span style={{ marginRight: "0.111rem" }}>
										Số dư:{" "}
										<b>
											<small>$</small>
											{isShow
												? Math.floor(profile.money).toLocaleString()
												: "*******"}
										</b>
										{isShow ? (
											<VisibilityOff onClick={() => setShow(!isShow)} />
										) : (
											<Visibility onClick={() => setShow(!isShow)} />
										)}
									</span>
								</div>
							) : (
								<div className="button-top">
									<Link to="/login" className="btn-login">
										Đăng nhập
									</Link>
									<Link to="/register" className="btn-register">
										Đăng ký
									</Link>
								</div>
							)}
						</div>
					</div>
				</div>
				{swiperParams && notify && (
					<Swiper {...swiperParams}>
						{notify.map((item, index) => (
							<>
								{index > 0 &&
								item.title !== "marquee" &&
								item.isShow === true ? (
									<SwiperSlide>
										<img
											alt={item.title.replace("}", "").replace("{", "")}
											src={item.image}
										/>
									</SwiperSlide>
								) : null}
							</>
						))}
					</Swiper>
				)}
				{notify ? (
					<>
						{notify[0].isShow == true && notify[0].title == "marquee" ? (
							<div className="marquees">
								<div>
									<div className="bell-icon"></div>
								</div>
								<div>
									<div
										className="chaychu"
										style={{
											animation:
												"chuchay " +
												notify[0].content.length / 10 +
												"s linear infinite",
										}}
										dangerouslySetInnerHTML={{
											__html: notify[0].content,
										}}
									/>
								</div>
							</div>
						) : null}
					</>
				) : null}

				<div className="content-game">
					<div class="game-title">
						<div class="title-content">
							<div class="fire-icon"></div>
							<span>Hot Nhất</span>
						</div>
					</div>
					<Swiper {...swiperGame}>
						<SwiperSlide>
							<div class="game-list-item">
								<div class="game-item" onClick={() => navigate("/keno1p")}>
									<img src="/assets/k1-aC7jKYof.png" class="game-img" />
									<div class="game-item-name">Keno 1P</div>
									<div class="game-item-display-name">OG</div>
								</div>
								<div class="game-item" onClick={() => navigate("/keno1p")}>
									<img src="/assets/KP0004-jvGeRA4E.png" class="game-img" />
									<div class="game-item-name">Tiến Lên</div>
									<div class="game-item-display-name">KP</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div class="game-list-item">
								<div class="game-item" onClick={() => navigate("/keno3p")}>
									<img src="/assets/k3-We1wcg_g.png" class="game-img" />
									<div class="game-item-name">Keno 3p</div>
									<div class="game-item-display-name">OG</div>
								</div>
								<div class="game-item" onClick={() => navigate("/keno3p")}>
									<img src="/assets/PG0047-0iUU-dqZ.png" class="game-img" />
									<div class="game-item-name">Long Sinh</div>
									<div class="game-item-display-name">PG</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div class="game-list-item">
								<div class="game-item" onClick={() => navigate("/xucxac5")}>
									<img src="/assets/k5-f-qJahMP.png" class="game-img" />
									<div class="game-item-name">Keno 5p</div>
									<div class="game-item-display-name">OG</div>
								</div>
								<div class="game-item" onClick={() => navigate("/keno1p")}>
									<img src="/assets/SEX009-X7BPc8lv.png" class="game-img" />
									<div class="game-item-name">Cua Tôm Cá Thái</div>
									<div class="game-item-display-name">SEXY</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div class="game-list-item">
								<div class="game-item" onClick={() => navigate("/keno3p")}>
									<img src="/assets/JL0045-slakq497.png" class="game-img" />
									<div class="game-item-name">Fortune Gems</div>
									<div class="game-item-display-name">JL</div>
								</div>
								<div class="game-item" onClick={() => navigate("/keno1p")}>
									<img src="/assets/PP0009-qBBM4cGb.png" class="game-img" />
									<div class="game-item-name">Đá quý Aztec</div>
									<div class="game-item-display-name">PP</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div class="game-list-item">
								<div class="game-item" onClick={() => navigate("/keno3p")}>
									<img src="/assets/SEX005-VZahBhJh.png" class="game-img" />
									<div class="game-item-name">Roulette</div>
									<div class="game-item-display-name">SEXY</div>
								</div>
								<div class="game-item" onClick={() => navigate("/keno1p")}>
									<img src="/assets/JDB069-wD3svyf8.png" class="game-img" />
									<div class="game-item-name">Siêu Bò B</div>
									<div class="game-item-display-name">JDB</div>
								</div>
							</div>
						</SwiperSlide>
					</Swiper>
					<div class="game-title">
						<div class="title-content">
							<div class="fire-icon"></div>
							<span>Nổ Hũ PG</span>
						</div>
					</div>
					<Swiper {...swiperGame}>
						<SwiperSlide>
							<div class="game-list-item">
								<div class="game-item" onClick={alertFalse}>
									<img src="/assets/PG0066-4mL_GSnp.png" class="game-img" />
									<div class="game-item-name">Kho báu của ngư dân</div>
									<div class="game-item-display-name">PG</div>
								</div>
								<div class="game-item" onClick={alertFalse}>
									<img src="/assets/PG0130-ygnAOavP.png" class="game-img" />
									<div class="game-item-name">Safari Hoang Dã</div>
									<div class="game-item-display-name">PG</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div class="game-list-item">
								<div class="game-item" onClick={alertFalse}>
									<img src="/assets/PG0125-vMMRmWaX.png" class="game-img" />
									<div class="game-item-name">Linh hồn huyền bí</div>
									<div class="game-item-display-name">PG</div>
								</div>
								<div class="game-item" onClick={alertFalse}>
									<img src="/assets/PG0129-znCD3Gol.png" class="game-img" />
									<div class="game-item-name">Du thuyền Hoàng Gia</div>
									<div class="game-item-display-name">PG</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div class="game-list-item">
								<div class="game-item" onClick={alertFalse}>
									<img src="/assets/PG0121-TRH28EQJ.png" class="game-img" />
									<div class="game-item-name">Cơn sốt bữa tiệc</div>
									<div class="game-item-display-name">PG</div>
								</div>
								<div class="game-item" onClick={alertFalse}>
									<img src="/assets/PG0127-yslYg6Px.png" class="game-img" />
									<div class="game-item-name">Lucky Clover Lady</div>
									<div class="game-item-display-name">PG</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div class="game-list-item">
								<div class="game-item" onClick={alertFalse}>
									<img src="/assets/PG0046-3Yl-OxxM.png" class="game-img" />
									<div class="game-item-name">Cách chơi mạt chược</div>
									<div class="game-item-display-name">PG</div>
								</div>
								<div class="game-item" onClick={alertFalse}>
									<img src="/assets/PG0126-94sR_0Xw.png" class="game-img" />
									<div class="game-item-name">Siêu Golf Drive</div>
									<div class="game-item-display-name">PG</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div class="game-list-item">
								<div class="game-item" onClick={alertFalse}>
									<img src="/assets/PG0133-zG4fYLhS.png" class="game-img" />
									<div class="game-item-name">UltimateStriker</div>
									<div class="game-item-display-name">PG</div>
								</div>
								<div class="game-item" onClick={alertFalse}>
									<img src="/assets/PG0124-hrSreRBg.png" class="game-img" />
									<div class="game-item-name">Lễ té nước Songkran</div>
									<div class="game-item-display-name">PG</div>
								</div>
							</div>
						</SwiperSlide>
					</Swiper>
					<div class="game-title">
						<div class="title-content">
							<div class="fire-icon"></div>
							<span>Nổ Hũ JILI</span>
						</div>
					</div>
					<Swiper {...swiperGame}>
						<SwiperSlide>
							<div class="game-list-item">
								<div class="game-item" onClick={alertFalse}>
									<img src="/assets/JL0033-vLKTDaCo.png" class="game-img" />
									<div class="game-item-name">Siêu Át</div>
									<div class="game-item-display-name">JL</div>
								</div>
								<div class="game-item" onClick={alertFalse}>
									<img src="/assets/JL0086-Ne2zA3nz.png" class="game-img" />
									<div class="game-item-name">Giành chiến thắng</div>
									<div class="game-item-display-name">JL</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div class="game-list-item">
								<div class="game-item" onClick={alertFalse}>
									<img src="/assets/JL0119-g9ufjxiN.png" class="game-img" />
									<div class="game-item-name">Mỏ vàng</div>
									<div class="game-item-display-name">JL</div>
								</div>
								<div class="game-item" onClick={alertFalse}>
									<img src="/assets/JL0085-LSF5kGDi.png" class="game-img" />
									<div class="game-item-name">Thợ săn</div>
									<div class="game-item-display-name">JL</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div class="game-list-item">
								<div class="game-item" onClick={alertFalse}>
									<img src="/assets/JL0099-J09x9x9H.png" class="game-img" />
									<div class="game-item-name">Aladin</div>
									<div class="game-item-display-name">JL</div>
								</div>
								<div class="game-item" onClick={alertFalse}>
									<img src="/assets/JL0084-p_E7EgfD.png" class="game-img" />
									<div class="game-item-name">Bingo may mắn</div>
									<div class="game-item-display-name">JL</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div class="game-list-item">
								<div class="game-item" onClick={alertFalse}>
									<img src="/assets/JL0101-ADp8H3Me.png" class="game-img" />
									<div class="game-item-name">Mèo Thần Tài</div>
									<div class="game-item-display-name">JL</div>
								</div>
								<div class="game-item" onClick={alertFalse}>
									<img src="/assets/JL0083-kmPmadiJ.png" class="game-img" />
									<div class="game-item-name">JILI CAISHEN</div>
									<div class="game-item-display-name">JL</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div class="game-list-item">
								<div class="game-item" onClick={alertFalse}>
									<img src="/assets/JL0117-RKBRKAuI.png" class="game-img" />
									<div class="game-item-name">Mật Địa Hoàng Kim</div>
									<div class="game-item-display-name">JL</div>
								</div>
								<div class="game-item" onClick={alertFalse}>
									<img src="/assets/JL0074-NZiymReH.png" class="game-img" />
									<div class="game-item-name">Bingo Carnaval</div>
									<div class="game-item-display-name">JL</div>
								</div>
							</div>
						</SwiperSlide>
					</Swiper>
				</div>
				<Footer />
			</div>
		</>
	);
}
export default Home;
